import React from "react"

import Layout from "../../../components/layout"
import ResearchArticle from "../../../components/researcharticle"
import SEO from "../../../components/seo"

const PCSArticlePage = () => (
  <Layout>
    <SEO title="Publications - PCS" description="" lang="en" meta={[]} />

    <ResearchArticle
      date="September 9th, 2020"
      title="PCS: A Productive Computational Science Platform"
      notes="Published at the International Conference on High Performance Computing &amp; Simulation (HPCS), Dublin, Ireland, September 2019."
      downloadLink=""
      fullTextLink="https://www.rc.ufl.edu/wp-content/uploads/2020/04/PCS-WEHA-HPCS2019-dojika.pdf"
      authors={[
        "Dave Ojika",
        "Ann Gordon-Ross",
        "Herman Lam",
        "Shinjae Yoo",
        "Younggang Cui",
        "Zhihua Dong",
        "Kirstin Kleese Van Dam",
        "Seyong Lee",
        "Thorsten Kurth",
      ]}
      abstract="As modern supercomputers continue to be increasingly heterogeneous with diverse computational accelerators (graphics processing units (GPUs), fieldprogrammable gate arrays (FPGAs), application specific integrated circuits (ASICs), etc.), software becomes a critical design aspect. Exploiting this new computational power requires increased software design time and effort to make valuable scientific discovery in the face of the complicated programming environments introduced by these accelerators. To address these challenges, we propose unifying multiple programming models into a single programming environment to facilitate large-scale, accelerator-aware, heterogeneous computing for next-generation scientific applications. This paper presents PCS, a productive computational science platform for cluster-scale heterogeneous computing. Focusing FPGAs, we describe the key concepts of the PCS platform and differentiate PCS from the current state-of-the-art, propose a new multi-FPGA architecture for graph-centric workloads (e.g., deep learning, etc.) with discussions on ongoing work."
    />
  </Layout>
)

export default PCSArticlePage
