import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { AiOutlineDownload, AiOutlineFileText } from "react-icons/ai"
import { MdExpandMore, MdExpandLess } from "react-icons/md"
interface Props {
  title: string
  authors: Array<string>
  abstract: string
  date: string
  notes: string
  fullTextLink: string
  downloadLink: string
}

const ResearchArticle = ({
  title,
  authors,
  abstract,
  date,
  notes,
  fullTextLink,
  downloadLink,
}: Props) => {
  const [expand, setExpand] = useState({
    authors: false,
  })

  let endIndex = expand.authors ? authors.length : 5

  return (
    <div className="research-article-full bg-darkened">
      <section className="article-head">
        <div className="container">
          <div className="col-lg-6 mx-auto">
            <h3>{date}</h3>
            <h1>{title}</h1>

            <div className="authors mb-3">
              {authors.slice(0, endIndex).map((author, i) => {
                // if we have expanded the text
                if (expand.authors) {
                  if (i !== authors.length - 1) {
                    return <span key={author}>{author}, </span>
                  }
                  return <span key={author}>{author}</span>
                }
                // if we have not expanded the text
                else {
                  if (i !== 4) {
                    return <span key={author}>{author}, </span>
                  }
                  return <span key={author}>{author}</span>
                }
              })}

              {!expand.authors ? (
                <MdExpandMore
                  className="expand"
                  onClick={() => setExpand({ ...expand, authors: true })}
                />
              ) : (
                <MdExpandLess
                  className="expand"
                  onClick={() => setExpand({ ...expand, authors: false })}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <h2>Other Notes</h2>
              <span>{notes}</span>
            </div>
            <div className="col-lg-6">
              <h2>Abstract</h2>
              <p>{abstract}</p>
            </div>
            <div className="col-lg-3 right-buttons">
              {/* <Button variant="primary" className="download-paper">
                <AiOutlineDownload />
                <a href={downloadLink}>Download Paper</a>
              </Button> */}
              <Button as="a" href={fullTextLink} target="_blank">
                <AiOutlineFileText />
                <span>View Full Text</span>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ResearchArticle
